import React from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import CreateConversionForm from 'modules/ConversionsModule/components/ConversionForm/CreateConversionForm';
import Alert from 'components/Dialogs/Alert';

const CreateConversionDrawer = (props) => {
  return (
    <AutomaticDrawer size='large' title='Create custom conversion' {...props}>
      <Alert
        type='warning'
        showIcon
        description='Complete these steps at your own pace to get up and running with MyDataNinja'
      />
      <CreateConversionForm />
    </AutomaticDrawer>
  );
};

CreateConversionDrawer.requiredParams = {
  create_conversion: true,
};

export default CreateConversionDrawer;
