import { Drawer } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { drawerWidth, shrinkDrawerWidth } from '@ninja/consts';
import React from 'react';
import { Link } from 'react-router-dom';
import P from 'router/paths';
import Logo from './Logo';
import PersonalAssistant from './PersonalAssistant';
import SidebarMenu from './SidebarMenu';
import SidebarToggler from './SidebarToggler';
import { useSharedData, useSidebar } from 'store/redux/states/ui/ui.hooks';
import Community from './Community';

const Sidebar = (props) => {
  const classes = useStyles();
  const [sidebarOpen] = useSidebar();
  const [open, , toggleSidebar] = useSidebar();
  const [assistentExpanded, setAssistedExpanded] = useSharedData('assistent_expanded');

  return (
    <Drawer
      anchor='left'
      onClose={toggleSidebar}
      variant='permanent'
      open={open}
      classes={{
        paper: clsx(classes.drawer, 'light-scroll', {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      {...props}
    >
      <Link to={P.DASHBOARD.INDEX} className='no-underline'>
        <Logo />
      </Link>
      <SidebarMenu />
      <div className={classes.sidebarBottom}>
        <Community
          target='_blank'
          sidebarOpen={sidebarOpen}
          text='Join Our Community'
          url='https://www.facebook.com/groups/971136084217074'
          icon={<img src='/icons/facebook.svg' className='w-5 h-5'/>}
        />
        <div
          onClick={() => (!assistentExpanded ? setAssistedExpanded(true) : null)}
          className={classes.personalAssistantContainer}
        >
          {!open && (
            <div className='relative'>
              <img
                src='https://image.crisp.chat/process/thumbnail/?url=https%3A%2F%2Fstorage.crisp.chat%2Fusers%2Favatar%2Foperator%2F59eb55cbd4828c00%2F2023-08-11-184611_1cync08.jpg&width=240&height=240&1712222085698'
                className='rounded-full w-10 absolute '
                alt=''
              />
            </div>
          )}
          <PersonalAssistant />
        </div>
        <SidebarToggler />
      </div>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    background: '#151a22',
    transition: '0.4s width ease-in',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      transition: '0.5s width ease-in',
    },
    position: 'static',
  },
  drawerOpen: {
    width: drawerWidth,
    '& $personalAssistantContainer': {
      padding: theme.spacing(2),
    },
  },
  drawerClose: {
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: shrinkDrawerWidth,
    },
  },
  personalAssistantContainer: {
    padding: '10px',
    paddingBottom: '20px',
  },
  sidebarBottom: {
    width: '100%',
    paddingTop: '5px',
  },
}));

export default Sidebar;
