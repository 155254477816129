import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'store/redux/baseQuery';

const ninjaApi = createApi({
  baseQuery: baseQuery,
  endpoints: (build) => ({
    fake: build.query({
      query: '/',
    }),
  }),
  reducerPath: 'api',
  tagTypes: [
    'User',
    'DefaultWorkspace',
    'Workspaces',
    'NetworkAccount',
    'CromFormIntegrations',
    'WorkspaceBilling',
    'Postbacks',
    'Reporting',
    'Dashboard',
    'Notifications',
    'Sales',
    'Form',
    'Rules',
    'Modules',
    'Permisssions',
    'Urls',
    'Domains',
    'Country',
    'InnerReporting',
    'InnerUrl',
    'AdCampaign',
    'AdCampaign',
    'AdAdset',
    'KeywordReporting',
    'AdAd',
    'FacebookPage',
    'AppsumoCode',
    'AppsumoBillingPlan',
    'AppsumoCode',
    'NinjaTable',
  ],
});

export default ninjaApi;
