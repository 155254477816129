import React, { Fragment, useState } from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import { useGetUrlTreeQuery } from 'modules/UrlModule/api/urls.api';
import { Divider, Skeleton, Switch, Typography } from 'antd';
import DailyStatisticsCustomData from 'modules/ReportingModule/pages/InnerReportingPage/pages/dashboard/DailyStatisticsCustomData';
import { useChangeAdStatusMutation } from 'store/redux/states/ad_ad/ad_ad.api';
import { useChangeAdsetStatusMutation } from 'store/redux/states/ad_adset/ad_adset.api';
import { useChangeCampaignStatusMutation } from 'store/redux/states/ad_campaign/ad_campaign.api';
import ConfirmModal from 'components/Dialogs/ConfirmModal';
import { snackbar } from 'components/notifications/notifications';

const AdDetailDrawer = (props) => {
  const { searchParams } = useQueryParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [currentTarget, setCurrentTarget] = useState(null);
  const { url_details } = searchParams;
  const [changeCampaignStatusQuery, { isLoading: isCampaignStatusLoading }] =
    useChangeCampaignStatusMutation();
  const [changeAdsetStatusQuery, { isLoading: isAdsetStatusLoading }] =
    useChangeAdsetStatusMutation();
  const [changeAdStatusQuery, { isLoading: isAdStatusLoading }] = useChangeAdStatusMutation();

  const { data, isLoading } = useGetUrlTreeQuery(
    { id: url_details },
    { skip: url_details === null }
  );

  const isActive = (status) => {
    return ['active', 'ENABLED'].indexOf(status) > -1;
  };

  const handleConfirm = () => {
    const { type, active, id } = currentAction;
    let query;
    switch (type) {
      case 'campaign':
        query = changeCampaignStatusQuery({ id, status: active ? 'active' : 'paused' });
        break;
      case 'adset':
        query = changeAdsetStatusQuery({ id, status: active ? 'active' : 'paused' });
        break;
      case 'ad':
        query = changeAdStatusQuery({ id, status: active ? 'active' : 'paused' });
        break;
      default:
        return;
    }
    query
      .unwrap()
      .then(() => {
        snackbar.success('Successfully changed status');
      })
      .catch(() => {
        snackbar.error('Error changing status');
      });
    setModalVisible(false);
  };

  const handleStatusChange = (type, active, id) => {
    setCurrentAction({ type, active, id });
    setCurrentTarget(type);
    setModalVisible(true);
  };

  return (
    <AutomaticDrawer size='large' title={data?.adset?.name} {...props}>
      <Skeleton loading={isLoading}>
        <div className='flex flex-col'>
          {data?.campaign ? (
            <div className='flex flex-col justify-center'>
              <Typography.Text disabled>Campaign</Typography.Text>
              <Typography.Text className='flex items-center gap-2'>
                <Switch
                  name='campaign'
                  value={data.campaign.id}
                  onChange={(active) => handleStatusChange('campaign', active, data.campaign.id)}
                  loading={isCampaignStatusLoading}
                  size='small'
                  checked={isActive(data.campaign.status)}
                />
                {data.campaign.name}
              </Typography.Text>
            </div>
          ) : null}
          {data?.has_children ? (
            <Fragment>
              {data?.adset ? (
                <div className='flex flex-col justify-center pl-4 pt-4'>
                  <Typography.Text disabled>Adset</Typography.Text>
                  <Typography.Text className='flex items-center gap-2'>
                    <Switch
                      size='small'
                      onChange={(active) => handleStatusChange('adset', active, data.adset.id)}
                      checked={isActive(data.adset.status)}
                    />
                    {data.adset.name}
                  </Typography.Text>
                </div>
              ) : null}
              {Array.isArray(data?.ads)
                ? data.ads.map((ad, index) => {
                    return (
                      <div key={index} className='flex flex-col justify-center pl-8 pt-4'>
                        {index === 0 ? <Typography.Text disabled>Ads</Typography.Text> : null}
                        <Typography.Text className='flex items-center gap-2'>
                          <Switch
                            onChange={(active) => handleStatusChange('ad', active, ad.id)}
                            size='small'
                            loading={isAdStatusLoading}
                            checked={isActive(ad.status)}
                          />
                          {ad.name}
                        </Typography.Text>
                      </div>
                    );
                  })
                : null}
            </Fragment>
          ) : null}
        </div>
      </Skeleton>
      <ConfirmModal
        visible={modalVisible}
        onConfirm={handleConfirm}
        onCancel={() => setModalVisible(false)}
        title={`Are you sure you want to turn ${
          currentAction?.active ? 'on' : 'off'
        } this ${currentTarget}?`}
        type='warning'
      />
      <Divider />

      {data?.adset?.id ? (
        <DailyStatisticsCustomData hideTitle innerId={data?.adset?.id} moduleId='reporting' />
      ) : null}
    </AutomaticDrawer>
  );
};

AdDetailDrawer.requiredParams = {
  url_details: true,
};

export default AdDetailDrawer;
