import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography } from 'antd';
import { AlertConfig } from './const';

const ConfirmModal = ({ title, visible, type, onConfirm, onCancel }) => {
  const alertConfig = AlertConfig[type];

  return (
    <Modal
      visible={visible}
      onOk={onConfirm}
      onCancel={onCancel}
      okText='Yes'
      centered
      cancelText='No'
    >
      <div className='grid items-center justify-center gap-4 mb-6'>
        <div className='grid items-center justify-center'>
          <img src={alertConfig.icon} alt={`${type} icon`} className='m-auto w-7 h-7' />
          <Typography className='text-sm font-semibold ' style={{ color: alertConfig.color }}>
            {alertConfig.title}
          </Typography>
        </div>
        <Typography className='text-sm font-normal'>{title}</Typography>
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default ConfirmModal;
