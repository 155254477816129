import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { SelectColumn } from 'components/table-formatters/CellSelectorFormatter';
import { DraggableHeaderRenderer } from './renderers/DraggableHeaderRenderer';
import useLocalStorage from '@ninja/hooks/useLocalStorage';

const useColumns = (
  defaultColumns,
  options = {
    sortable: false,
    selector: false,
    SelectColumn: SelectColumn,
  }
) => {
  const { id } = options;

  /* Declare column states */
  const [columns, setColumns] = useState(defaultColumns || []);

  const [columnsOrder, setColumnsOrder] = useLocalStorage({
    key: `ninjatable_${id}`,
    initialData: [],
  });

  useEffect(() => {
    if (!id) {
      setColumns(defaultColumns);
      return;
    }
    const sortedColumns = defaultColumns.sort((a, b) => {
      const aIndex = columnsOrder.indexOf(a.key);
      const bIndex = columnsOrder.indexOf(b.key);
      return aIndex - bIndex;
    });
    setColumns(sortedColumns);
  }, [defaultColumns]);

  const props = useMemo(() => {
    return {
      DraggableComponent: Fragment,
      columns: columns,
    };
  }, [columns]);

  function HeaderRenderer(props) {
    return <DraggableHeaderRenderer {...props} onColumnsReorder={handleColumnsReorder} />;
  }

  function handleColumnsReorder(sourceKey, targetKey) {
    const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
    const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
    const reorderedColumns = [...columns];

    reorderedColumns.splice(targetColumnIndex, 0, reorderedColumns.splice(sourceColumnIndex, 1)[0]);

    setColumns(reorderedColumns);

    if (id) {
      setColumnsOrder(reorderedColumns.map((c) => c.key));
    }
  }

  const draggableColumns = columns.map((c) => {
    if (c.key === 'id') return c;
    return { ...c, headerRenderer: HeaderRenderer };
  });

  useEffect(() => {
    if (props.selectable) {
      setColumns([{ ...SelectColumn, name: '' }, ...columns]);
    } else {
      setColumns(columns);
    }
  }, [columns]);

  if (options.sortable) {
    props.DraggableComponent = DndProvider;
    props.columns = draggableColumns;
  }

  return props;
};

export default useColumns;
