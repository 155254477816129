import ninjaApi from 'store/redux/apis/ninja.api';

export const reportingApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['Reporting'],
  endpoints: (builder) => ({
    // load creative reporting data
    creativeReporting: builder.query({
      query: (body) => ({
        url: '/reporting/creative/load',
        method: 'POST',
        body,
      }),
      providesTags: ['CreativeReporting'],
    }),

    // load creative reporting video
    creativeReportingVideo: builder.query({
      query: (body) => ({
        url: '/reporting/creative/video',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useReportingTableDataQuery,
  useInnerReportingByAgeDataQuery,
  useInnerCombinationsDataQuery,
  useChangeStatusMutation,
  useInnerAdDataQuery,
  useChangeAdsetBudgetMutation,
  useCreativeReportingQuery,
  useCreativeReportingVideoQuery,
} = reportingApi;
