import { useFormik } from 'formik';
import React, { useState } from 'react';
import {
  useCreateFacebookConversionMutation,
  useCreateGoogleConversionMutation,
} from 'modules/ConversionsModule/api/conversions.api';
import {
  Input,
  InputNumber,
  Radio,
  Checkbox,
  Typography,
  Flex,
  Popover,
  Space,
  Button,
} from 'antd';
import Icon from '@mdi/react';
import { mdiHelpCircle } from '@mdi/js';

const CreateConversionForm = () => {
  const [createFacebookConversion] = useCreateFacebookConversionMutation();
  const [createGoogleConversion] = useCreateGoogleConversionMutation();

  const [formState] = useState({
    networks: ['facebook', 'google', 'google_search'],
    conversionName: '',
    pixel: '',
    category: '',
    defaultValue: '',
    valueOption: 'same',
    conversionWindow: '',
  });

  const handleConversionCreation = () => {
    form.values.networks.some((network) => network === 'facebook') &&
      createFacebookConversion({ formState }).unwrap();

    form.values.networks.some((network) => network === 'google') &&
      createGoogleConversion({ formState }).unwrap();
  };

  const form = useFormik({
    initialValues: {
      networks: ['facebook', 'google', 'google_search'],
      conversionName: '',
      pixel: '',
      category: '',
      defaultValue: undefined,
      valueOption: 'same',
      conversionWindow: '',
    },
    onSubmit: handleConversionCreation,
    validateOnChange: true,
  });

  console.log(form.values);

  return (
    <div className='bg-white grid gap-4 mt-5'>
      <Flex gap='small' horizontal align='center'>
        <Typography.Text className='text-sm font-medium'>Choose Networks</Typography.Text>
        <Popover
          destroyTooltipOnHide
          placement='right'
          content={
            <div className='w-64'>
              Choose between pre-filling the list or starting with an empty one choose between
              pre-filling the list or starting
            </div>
          }
          title='Network'
          trigger='hover'
        >
          <Icon path={mdiHelpCircle} size={0.8} className='cursor-pointer text-color-light' />
        </Popover>
      </Flex>
      <Checkbox.Group
        options={[
          {
            label: 'Facebook',
            value: 'facebook',
          },
          {
            label: 'Google',
            value: 'google',
          },
        ]}
        value={form.values.networks}
        onChange={(value) => {
          form.setFieldValue('networks', value);
        }}
      />
      <Input
        size='large'
        placeholder='Conversion Name'
        name='conversionName'
        value={form.values.conversionName}
        addonAfter={
          <Popover
            destroyTooltipOnHide
            placement='left'
            content={
              <div className='w-64'>
                A conversion occurs when a visitor to your website completes a desired goal, suchas
                filling out a form or making a purchase. The percentage of total visitors
                thatconvert is called your conversion rate. Depending on your site&lsquo;s or
                business&lsquo;sgoals.
              </div>
            }
            title='Conversion Name'
            trigger='hover'
          >
            <Icon path={mdiHelpCircle} size={1} className='cursor-pointer text-color-light' />
          </Popover>
        }
        onChange={form.handleChange}
      />

      {form.values.networks.find((network) => network === 'google') && (
        <>
          <InputNumber
            size='large'
            placeholder='Default value'
            name='defaultValue'
            value={form.values.defaultValue || 0}
            addonAfter={
              <Popover
                destroyTooltipOnHide
                placement='left'
                content={
                  <div className='w-64'>
                    In this case, the conversion rate for installs and in-app purchases both need to
                    be considered in your analysis to learn how to convert users from install to
                    purchase
                  </div>
                }
                title='Default Value'
                trigger='hover'
              >
                <Icon path={mdiHelpCircle} size={1} className='cursor-pointer text-color-light' />
              </Popover>
            }
            onChange={(e) => {
              let test = e.toString().replace(/^[0.e]/, '');
              form.setFieldValue('defaultValue', test);
            }}
          />

          <Flex gap='small' horizontal align='center'>
            <Typography.Text className='text-sm font-medium'>Value Options</Typography.Text>{' '}
            <Popover
              destroyTooltipOnHide
              placement='right'
              content={
                <div className='w-64'>
                  In this case, the conversion rate for installs and in-app purchases both need to
                  be considered in your analysis to learn how to convert users from install to
                  purchase.
                </div>
              }
              title='Value Options'
              trigger='hover'
            >
              <Icon path={mdiHelpCircle} className='cursor-pointer text-color-light' size={0.8} />
            </Popover>
          </Flex>
          <Radio.Group
            name='valueOption'
            size='small'
            onChange={({ target: { value } }) => {
              form.setFieldValue('valueOption', value);
            }}
            value={form.values.valueOption}
          >
            <Space direction='vertical'>
              <Radio value='same'>Use the same value for each conversion</Radio>
              <Radio value='different'>Use different values for each conversion</Radio>
              <Radio value='dont'>
                Dont use the value for this conversion action (Not Recommended)
              </Radio>
            </Space>
          </Radio.Group>

          <Input
            size='large'
            placeholder='Click through conversion window'
            name='conversionWindow'
            value={form.values.conversionWindow}
            onChange={form.handleChange}
            addonAfter={
              <Popover
                destroyTooltipOnHide
                placement='right'
                content={
                  <div className='w-64'>
                    In this case, the conversion rate for installs and in-app purchases both need to
                    be considered in your analysis to learn how to convert users from install to
                    purchase.
                  </div>
                }
                title='Click-through'
                trigger='hover'
              >
                <Icon path={mdiHelpCircle} size={1} className='cursor-pointer text-color-light' />
              </Popover>
            }
          />
        </>
      )}
      <Button size='large' type='primary' onClick={form.handleSubmit}>
        Create
      </Button>
    </div>
  );
};

export default CreateConversionForm;

// info for
// {
//   id: 1,
//   title: 'Pixel',
//   text: "When a customer completes an action that you've defined as valuable, these customer actions are called conversions",
// },
// {
//   id: 1,
//   title: 'Category',
//   text: 'Learn about Facebook Conversions API, one of the Facebook Business Tools that lets you share key web and offline events.',
// },
