import React from 'react';
import { Button } from 'antd';
import { mdiPlusCircle } from '@mdi/js';
import Icon from '@mdi/react';
import DocumentationButton from 'components/buttons/DocumentationButton';
import useQueryParams from 'hooks/useQueryParams';

const CustomUrlMainMenu = () => {
  const { setUrlParams, searchParams } = useQueryParams();

  return (
    <div className='flex my-1 items-center px-4 py-1 pr-8 justify-between'>
      <Button
        className='bg-error-background text-orange border-none font-medium '
        onClick={() => setUrlParams({ create: true })}
        icon={<Icon path={mdiPlusCircle} size={0.7} />}
      >
        Add New Url
      </Button>
      <DocumentationButton
        type='primary'
        tooltip='View docs'
        link='https://mydataninja.com/what-are-custom-urls-and-how-to-use-them/'
      />
    </div>
  );
};

export default CustomUrlMainMenu;
