import React from 'react';
import ConversionRulesForm from '../forms/ConversionRulesForm';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import { snackbar } from 'components/notifications/notifications';
import { useCreateConversionRuleMutation } from 'modules/ConversionRulesModule/api/conversion-rules.api';
import { WIZARD_STEP, useWizard } from 'modules/WizardModule/wizardUtils';
import { useHistory } from 'react-router-dom';
import { AlertType } from 'components/Dialogs/const';
import P from 'router/paths';

const ConversionRulesCreateDrawer = (props) => {
  const [createConversionRule, { isLoading: isCreateLoading }] = useCreateConversionRuleMutation();
  const history = useHistory();
  const { wizard, goToNextStep } = useWizard();
  const { removeParams } = useQueryParams();

  const onSubmit = (data) => {
    createConversionRule(data)
      .unwrap()
      .then((d) => {
        removeParams(['create_conversion_rule']);
        snackbar.success(`${data.name} URL Created Successfully`);
        conversionRuleSuccessfullySubmitted();
      });
  };

  const conversionRuleSuccessfullySubmitted = () => {
    if (wizard === WIZARD_STEP.conversion_rules) {
      goToNextStep(wizard);
      window.alert(
        'Redirect to Wizard page ?',
        () => {
          history.push(P.WIZARD.INDEX);
        },
        { type: AlertType.Success }
      );
    }
    history.push(P.CONVERSION_RULES.INDEX);
  };

  return (
    <AutomaticDrawer size='large' title='Create Conversion Rules' {...props}>
      <ConversionRulesForm onSubmit={onSubmit} loading={isCreateLoading} />
    </AutomaticDrawer>
  );
};

ConversionRulesCreateDrawer.requiredParams = {
  create_conversion_rule: true,
};

export default ConversionRulesCreateDrawer;
