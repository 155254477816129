import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from 'antd';

const SelectCellFormatter = (props) => {
  const { value, disabled, onClick, onChange } = props;

  function handleChange(e) {
    onChange(e.target.checked, e.nativeEvent.shiftKey);
  }

  return (
    <div className='flex w-full'>
      <Checkbox disabled={disabled} checked={value} onChange={handleChange} onClick={onClick} />
    </div>
  );
};

SelectCellFormatter.propTypes = {
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

export default SelectCellFormatter;
