import { Select, Space } from 'antd';
import NetworkIcon from 'components/NetworkIcon/NetworkIcon';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ninjaApi from 'store/redux/apis';
import { StatusFormatter } from 'components/tables/NinjaTable/formatters';
import { LoadingOutlined } from '@ant-design/icons';
import NetworkSwitch from 'components/switches/NetworkSwitch';
import debounce from 'common/debounce';

const ConversionsSelector = (props) => {
  const {
    value = [],
    network = 'all',
    onChange,
    multiple = true,
    disableNetworksSelector = false,
    placeholder = 'Conversion',
    returnObject = false,
    label = 'Type To Search',
    preload = 20,
    disableSelectAll = true,
    defaultValue,
    networkSwitch = true,
    account_id,
    ...rest
  } = props;

  const [search, setSearch] = useState();
  const [id, setId] = useState(defaultValue);
  const [open, setOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [load, setLoad] = useState(preload);
  const [selectedNetwork, setSelectedNetwork] = useState(network);
  const [getSearchConversionQuery, { data = [], isFetching }] =
    ninjaApi.useLazySearchConversionQuery();

  const fetchConversion = useCallback(() => {
    getSearchConversionQuery({
      network: selectedNetwork,
      search: search,
      ids: defaultValue ? defaultValue : value,
      preload: load,
      account_id: account_id,
    }).unwrap();
  }, [selectedNetwork, search, defaultValue, id, load]);

  useEffect(() => {
    setId(defaultValue);
  }, [defaultValue]);

  const onClear = () => {
    setId([]);
  };

  useEffect(() => {
    if (open && id) {
      setId([]);
    }
  }, [open]);

  useEffect(() => {
    open && fetchConversion();
  }, [fetchConversion]);
  useEffect(() => {
    fetchConversion();
  }, [fetchConversion]);

  const handleChange = useCallback((selectedValues) => {
    const selectedOptions = options.filter((opt) => selectedValues.includes(opt.id));
    let result;

    if (returnObject && value.id) {
      result = selectedOptions;
    } else {
      result = selectedValues;
    }
    onChange?.(result);
  });

  const handleNetworksChange = useCallback((network) => {
    setSelectedNetwork(network);
  });

  const options = useMemo(() => {
    return data.filter((acc) => selectedNetwork === 'all' || acc.network === selectedNetwork);
  }, [data, selectedNetwork]);

  useEffect(() => {
    handleSearch('');
  }, [selectedNetwork]);

  const handleSearch = debounce((searchValue) => {
    setSearch(searchValue);
  }, 200);

  const renderNotFoundContent = () => {
    return (
      <LoadingOutlined className='text-3xl m-auto flex items-center justify-center h-9' spin />
    );
  };

  useEffect(() => {
    if (scrollPosition > 90 && !(load > data.length)) {
      setTimeout(() => {
        setLoad(load + 50);
      }, 200);
    }
  }, [scrollPosition]);

  const handleClose = () => {
    setOpen(!open);
    setSearch('');
  };

  const onSearch = (value) => {
    handleSearch(value);
  };

  return (
    <div className='w-full'>
      <Select
        className='w-full'
        open={open}
        mode='multiple'
        placeholder={open ? label : placeholder}
        maxTagCount='responsive'
        onChange={handleChange}
        value={value}
        onClear={onClear}
        allowClear
        optionFilterProp='label'
        onDropdownVisibleChange={handleClose}
        onPopupScroll={(e) => {
          const { scrollTop, scrollHeight, clientHeight } = e.target;
          const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
          if (scrollPercentage > 90) {
            setScrollPosition(scrollPercentage);
          }
        }}
        notFoundContent={isFetching && options.length === 0 ? renderNotFoundContent() : undefined}
        onSearch={onSearch}
        filterOption={(input, option) => {
          const isMatch = option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          return isMatch;
        }}
        dropdownRender={(menu) => (
          <>
            {networkSwitch && (
              <NetworkSwitch
                selectedNetwork={selectedNetwork}
                handleNetworksChange={handleNetworksChange}
                disableNetworksSelector={disableNetworksSelector}
                disableSelectAll={disableSelectAll}
              />
            )}
            <div>
              {menu}
              {isFetching && options.length ? (
                <div className='w-full m-auto text-center'>
                  <LoadingOutlined className='m-auto' />
                </div>
              ) : null}
            </div>
          </>
        )}
        {...rest}
      >
        {options?.map((opt, index) => {
          return (
            <Select.Option key={index} value={opt.id} label={opt.name}>
              <Space className='flex items-center justify-between w-full'>
                <div className='flex items-center justify-center w-full gap-2'>
                  <StatusFormatter row={opt} title={false} />
                  <span role='img' aria-label={opt.name}>
                    <NetworkIcon network={opt.network} size={15} />
                  </span>
                  <span className='font-medium text-xs'>{opt.name}</span> -
                  <span className='bg-info-background rounded-full text-xs p-1'>
                    {opt.network_account}
                  </span>
                </div>
              </Space>
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};
export default ConversionsSelector;
