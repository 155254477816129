import React, { useState, useMemo, useEffect } from 'react';
import { Button } from 'antd';
import { mdiPlusCircle } from '@mdi/js';
import Icon from '@mdi/react';
import useQueryParams from 'hooks/useQueryParams';

const ConversionRulesMainMenu = () => {
  const { setUrlParams } = useQueryParams();

  return (
    <div className='flex my-1 items-center px-4 py-1 pr-8 justify-between'>
      <Button
        className='bg-error-background text-orange border-none font-medium'
        onClick={() => setUrlParams({ create_conversion_rule: true })}
        icon={<Icon path={mdiPlusCircle} size={0.7} />}
      >
        Create Rule
      </Button>
    </div>
  );
};

export default ConversionRulesMainMenu;
