import React from 'react';
import ConversionRulesForm from '../forms/ConversionRulesForm';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import { snackbar } from 'components/notifications/notifications';
import { useUpdateConversionRuleMutation } from 'modules/ConversionRulesModule/api/conversion-rules.api';
import { WIZARD_STEP, useWizard } from 'modules/WizardModule/wizardUtils';
import { useHistory } from 'react-router-dom';
import { AlertType } from 'components/Dialogs/const';
import P from 'router/paths';

const ConversionRulesEditDrawer = (props) => {
  const [updateConversionRule, { isLoading: isUpdateLoading }] = useUpdateConversionRuleMutation();

  const history = useHistory();
  const { wizard, goToNextStep } = useWizard();
  const { removeParams, searchParams } = useQueryParams();
  const { conversion_rule_id } = searchParams;

  const onSubmit = (data) => {
    updateConversionRule(data)
      .unwrap()
      .then((d) => {
        removeParams(['update', 'conversion_rule_id']);
        snackbar.success(`${data.name} URL Updated Successfully`);
        conversionRuleSuccessfullySubmitted();
      });
  };

  const conversionRuleSuccessfullySubmitted = () => {
    if (wizard === WIZARD_STEP.conversion_rules) {
      goToNextStep(wizard);
      window.alert(
        'Redirect to Wizard page ?',
        () => {
          history.push(P.WIZARD.INDEX);
        },
        { type: AlertType.Success }
      );
    }
    history.push(P.CONVERSION_RULES.INDEX);
  };

  return (
    <AutomaticDrawer size='large' title='Edit Conversion Rules' {...props}>
      <ConversionRulesForm
        onSubmit={onSubmit}
        loading={isUpdateLoading}
        editing={true}
        id={conversion_rule_id}
      />
    </AutomaticDrawer>
  );
};

ConversionRulesEditDrawer.requiredParams = {
  conversion_rule_id: (p) => parseInt(p) > 0,
  edit: true,
};

export default ConversionRulesEditDrawer;
