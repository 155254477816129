import ninjaApi from 'store/redux/apis/ninja.api';

export const conversionsApi = ninjaApi.injectEndpoints({
  tagTypes: ['Conversion'],
  overrideExisting: true,
  endpoints: (builder) => ({
    /**
     * Get All Conversions
     */
    conversionsList: builder.query({
      query: (body) => ({
        url: '/network/conversions',
        method: 'POST',
        body,
      }),
      providesTags: ['Conversion'],
    }),

    /**
     * Load Conversion Logs
     */
    conversionsLogs: builder.query({
      query: (body) => ({
        url: '/network/conversions/logs/list',
        method: 'POST',
        body,
      }),
      providesTags: ['Conversion'],
    }),

    /**
     * cerate facebook custom conversion
     */
    createFacebookConversion: builder.mutation({
      query: (body) => ({
        url: '/facebook/conversions/create',
        method: 'POST',
        body,
      }),
      providesTags: ['Conversion'],
    }),

    /**
     * get facebook  conversion
     */
    getFacebookConversions: builder.query({
      query: (body) => ({
        url: '/facebook/conversions/get',
        method: 'POST',
        body,
      }),
      providesTags: ['Conversion'],
    }),

    /**
     * cerate google custom conversion
     */
    createGoogleConversion: builder.mutation({
      query: (body) => ({
        url: '/google/conversions/create',
        method: 'POST',
        body,
      }),
      providesTags: ['Conversion'],
    }),

    /**
     * get google  conversion
     */
    googleConversions: builder.query({
      query: (body) => ({
        url: '/google/conversions/get',
        method: 'POST',
        body,
      }),
      providesTags: ['Conversion'],
    }),

    /**
     * get conversion
     */
    getConversions: builder.query({
      query: (body) => ({
        url: '/conversions/get',
        method: 'POST',
        body,
      }),
      providesTags: ['Conversion'],
    }),

    /**
     * send conversion
     */
    sendConversions: builder.mutation({
      query: (body) => ({
        url: '/conversions/send',
        method: 'POST',
        body,
      }),
      providesTags: ['Conversion'],
    }),

    searchConversion: builder.query({
      query: (body) => ({
        url: 'network/conversions/search',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useConversionsListQuery,
  useConversionsLogsQuery,
  useCreateFacebookConversionMutation,
  useGetFacebookConversionsQuery,
  useCreateGoogleConversionMutation,
  useGoogleConversionsQuery,
  useSendConversionMutation,
  useSearchConversionQuery,
} = conversionsApi;
